import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';
import moment from 'moment';

export default function Referral(currency) {
  const state = reactive({
    _affiliate: null,
    loading: false,
    referralList: [],
    referralCommissions: [],
    commissionTotals: {},
    total1: 0,
    options1: {
      page: 0,
      rowsPerPage: 20,
    },
    total2: 0,
    options2: {
      page: 0,
      rowsPerPage: 20,
    },
    filters: {
      dateRangeSelected: 'Current Month',
      dateRange: {
        fromDate: moment().utc().startOf('month').toISOString(),
        toDate: moment().utc().endOf('day').toISOString(),
      },
    },
    currencySymbol: computed(() => (currency === 'USD' ? '$' : currency === 'EUR' ? '€' : '')),
  });

  const actions = {
    async loadReferralList() {
      try {
        _.set(state, 'loading', true);
        const result = await HttpClientV2.callFunctionV2('GET', 'referral', {
          type: 'referrerAffiliate',
          _affiliate: state._affiliate,
          limit: state.options1.rowsPerPage,
          page: state.options1.page,
          sortBy: state.options1.sortBy,
          descending: state.options1.descending,
        });
        _.set(state, 'referralList', result.resultSet);
        _.set(state, 'total1', result.total);
      } catch (error) {
        _.set(state, 'referralList', []);
        _.set(state, 'total1', 0);
      } finally {
        _.set(state, 'loading', false);
      }
    },
    async loadCommissions() {
      try {
        _.set(state, 'loading', true);
        const result = await HttpClientV2.callFunctionV2('GET', 'report/referral', {
          _affiliate: state._affiliate,
          _referrerAffiliate: state._affiliate,
          fromDate: state.filters.dateRange.fromDate,
          toDate: state.filters.dateRange.toDate,
          limit: state.options2.rowsPerPage,
          page: state.options2.page,
          sortBy: state.options2.sortBy,
          descending: state.options2.descending,
        });
        _.set(state, 'referralCommissions', result.resultSet);
        _.set(state, 'commissionTotals', result.meta);
        _.set(state, 'total2', result.total);
      } catch (error) {
        _.set(state, 'referralCommissions', []);
        _.set(state, 'commissionTotals', {});
        _.set(state, 'total2', 0);
      } finally {
        _.set(state, 'loading', false);
      }
    },
    async applyDateFilter(value) {
      let fromDate;
      let toDate;
      switch (value) {
        case 'Current Month':
          fromDate = moment().utc().startOf('month');
          toDate = moment().utc().endOf('day');
          break;
        case 'Last Month':
          fromDate = moment().utc().subtract(1, 'month').startOf('day');
          toDate = moment().utc().endOf('day');
          break;
        case 'Last 3 Months':
          fromDate = moment().utc().subtract(3, 'month').startOf('day');
          toDate = moment().utc().endOf('day');
          break;
        case 'Last 6 Months':
          fromDate = moment().utc().subtract(6, 'month').startOf('day');
          toDate = moment().utc().endOf('day');
          break;
        case 'All Time':
          fromDate = moment('2020-01-01').utc().startOf('day');
          toDate = moment().utc().endOf('day');
          break;
      }
      _.set(state, 'filters.dateRange.fromDate', fromDate.toISOString());
      _.set(state, 'filters.dateRange.toDate', toDate.toISOString());
      await this.loadCommissions();
    },
    async applyOptions1(value) {
      let keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        _.set(state, `options1.${key}`, value[key]);
      }
      await this.loadReferralList();
    },
    async applyOptions2(value) {
      let keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        _.set(state, `options2.${key}`, value[key]);
      }
      await this.loadCommissions();
    },
  };
  return { state, actions };
}
