import _ from 'lodash';
import capitalize from 'lodash/capitalize';
import upperFirst from 'lodash/upperFirst';
import kebabCase from 'lodash/kebabCase';
import { reactive, computed } from '@vue/composition-api';
import HttpClientV2 from '../HttpClientV2';
import moment from 'moment-timezone';
import ItemState from '../constants/ItemState';
import Operation from '../constants/Operation';

const affSubs = ['aff_sub', 'aff_sub1', 'aff_sub2', 'aff_sub3', 'aff_sub4', 'aff_sub5'];
const deviceTypes = [
  'deviceBrand',
  'deviceClient',
  'deviceOs',
  'deviceType',
  'deviceClientBrowser',
];
const deviceObject = {
  deviceBrand: 'Device Brand',
  deviceClient: 'Device Client',
  deviceOs: 'Device OS',
  deviceType: 'Device Type',
  deviceClientBrowser: 'Browser',
};
const hiddenHeaders = ['uniqueTransaction'];

export default function ReportTS(currency) {
  const state = reactive({
    symbol: computed(() => (currency == 'USD' ? '$' : '€')),
    operation: Operation.NONE,
    items: [],
    total: 0,
    offers: [],
    countries: [],
    exportId: '',
    exportOwner: '',
    exportURL: '',
    filters: {
      dateRange: {
        fromDate: moment().startOf('day').tz('GMT', true),
        toDate: moment().endOf('day').tz('GMT', true),
      },
    },
    options: {
      groupBy: 'day',
      headers: ['uniqueTransaction'],
    },
    calculations: [
      {
        name: 'CR',
        value: 'cr',
      },
      {
        name: 'EPC',
        value: `epc${currency}`,
      },
      {
        name: 'Payout',
        value: `payout${currency}`,
      },
      {
        name: 'Unique Clicks',
        value: 'uniqueTransaction',
        isHeader: true,
      },
      {
        name: 'Conversions',
        value: 'conversions',
      },
    ],
    headers: computed(() => {
      let headers = [];
      headers.push({
        text: capitalize(state.options.groupBy),
        value: `${state.options.groupBy}`,
        align: 'start',
        width: 100,
      });
      for (let index = 0; index < state.options.headers.length; index++) {
        if (deviceTypes.includes(state.options.headers[index])) {
          headers.push({
            text: deviceObject[state.options.headers[index]],
            value: `${state.options.headers[index]}`,
            align: 'start',
            sortable: true,
          });
        } else if (affSubs.includes(state.options.headers[index])) {
          headers.push({
            text: upperFirst(state.options.headers[index]),
            value: `${state.options.headers[index]}`,
            align: 'start',
            sortable: true,
          });
        } else if (!hiddenHeaders.includes(state.options.headers[index])) {
          headers.push({
            text: upperFirst(kebabCase(state.options.headers[index])),
            value: `${state.options.headers[index]}`,
            align: 'start',
            sortable: true,
          });
        }
      }
      for (let index = 0; index < state.calculations.length; index++) {
        if (
          state.calculations[index].isHeader === true &&
          !state.options.headers.includes(state.calculations[index].value)
        ) {
          continue;
        }
        headers.push({
          text: state.calculations[index].name,
          value: state.calculations[index].value,
          align: 'center',
          sortable: [
            `payout${currency}`,
            'transactions',
            'conversions',
            'cr',
            `epc${currency}`,
          ].includes(state.calculations[index].value),
        });
      }
      return headers;
    }),
    item: null,
    application: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
    showTotals: false,
    csvUrl: '',
    initialState: true,
    exportCsv: true,
    doneCsv: false,
    errorCsv: false,
    exportingCsv: false,
    errorDescriptionCsv: '',
    showErrorDescriptionCsv: false,
    isLoadingRunReportButton: computed(() => state.loading || state.exportingCsv),
    isExportOnly: computed(() => {
      return (
        affSubs.some((affSub) => state.options.headers.includes(affSub)) &&
        state.options.groupBy === 'day'
      );
    }),
    showExportButton: computed(() => !state.doneCsv && !state.errorCsv),
    disableExportButton: computed(
      () => (state.initialState && !state.isExportOnly) || state.loading || state.exportingCsv
    ),
    showExportReport: computed(
      () => (state.exportCsv || state.isExportOnly) && !state.exportingCsv
    ),
    showExportingReport: computed(() => state.exportingCsv),
    showDownloadButton: computed(() => state.doneCsv && !state.errorCsv),
  });

  const actions = {
    async applyFilter({ key, value }) {
      this.resetCsvValues();
      if (_.isNil(value) || _.isEmpty(value)) {
        _.unset(state, `filters.${key}`);
      } else {
        _.set(state, `filters.${key}`, value);
      }
    },
    async resetFilters() {
      state.filters = {};
      state.filters.dateRange = {
        fromDate: moment().startOf('day').tz('GMT', true),
        toDate: moment().endOf('day').tz('GMT', true),
      };
    },
    async removeFilter({ key }) {
      this.resetCsvValues();
      delete state['filters'][key];
      await this.loadItems({});
    },
    async applyOptions({ value }) {
      let keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        _.set(state, `options.${key}`, value[key]);
      }

      if (!state.initialState) {
        await this.loadItems();
      }
    },
    async applyHeadersOptions({ value }) {
      _.set(state, 'options.headers', value);
    },
    async applyGroupBy({ value }) {
      this.resetCsvValues();
      _.set(state, 'options.groupBy', value);
    },
    async applyHeaders({ value }) {
      this.resetCsvValues();
      _.set(state, 'headers', value);
    },
    async applyCalculations({ value }) {
      _.set(state, 'calculations', value);
    },
    affiliateCurrency() {
      return currency;
    },
    async loadItems() {
      if (_.isEqual(_.get(state, 'operation'), Operation.NONE)) {
        try {
          _.set(state, 'operation', Operation.IS_GETTING);
          if (state.initialState) {
            _.set(state, 'initialState', false);
          }
          let filters = Object.keys(state.filters).length > 0 ? state.filters : {};
          let options = _.get(state, 'options');
          let calculations = state.calculations.filter((calc) => calc.isHeader !== true);
          const payload = {
            ...filters,
            ...options,
            calculations,
            limit: options.rowsPerPage ?? 20,
            rowsPerPage: undefined,
          };
          const response = await HttpClientV2.callFunctionV2('POST', 'report/ts-report', payload);
          const result = response.data;
          if (options.groupBy === 'hour' && result.resultSet.length > 0) {
            result.resultSet.forEach((element) => {
              let createdAt = moment(element.createdAt).minutes(0).seconds(0);
              element.createdAt = createdAt.format('DD/MM/YYYY - HH:mm');
            });
          }
          _.set(state, 'items', result.resultSet);
          _.set(state, 'total', result.total);
          _.set(state, 'totals', result.totals);
          this.setShowTotals(true);
        } catch (error) {
          _.set(state, 'items', []);
          _.set(state, 'total', 0);
        } finally {
          _.set(state, 'options.page', 0);
          _.set(state, 'operation', Operation.NONE);
        }
      }
    },
    async downloadButtonHandler() {
      _.set(state, 'initialState', true);
      _.set(state, 'csvUrl', '');
      _.set(state, 'doneCsv', false);
      _.set(state, 'exportingCsv', false);
      _.set(state, 'exportCsv', true);
    },
    async loadFilterItems() {
      const offerParams = {
        limit: 0,
        page: 0,
        columns: '_id,name',
        disablePagination: 'true',
      };

      const offers = await HttpClientV2.callFunctionV2('GET', 'offer', offerParams);
      state.offers = offers.resultSet;

      const countryParams = {
        limit: 1000,
        page: 0,
        sortBy: 'name',
        descending: -1,
      };
      const result = await HttpClientV2.callFunctionV2('GET', 'country', countryParams);
      state.countries = _.sortBy(
        result.resultSet.filter((l) => !_.isEmpty(l.name)),
        'name'
      );
    },
    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
    },
    async unselectItem() {
      _.set(state, 'application', null);
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
      _.set(state, 'operation', Operation.NONE);
      await this.loadItems();
    },
    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },
    async updateAttribute({ key, value }) {
      _.set(state, `${key}`, value);
    },
    async updateItemAttribute({ key, value }) {
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },
    async markAsModified() {
      let itemState = _.get(state, 'itemState');
      if (itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
    async setShowTotals(value) {
      if (value === true) {
        _.set(state, 'showTotals', true);
      }
    },
    resetCsvValues() {
      _.set(state, 'exportCsv', true);
      _.set(state, 'errorCsv', false);
      _.set(state, 'doneCsv', false);
      _.set(state, 'showErrorDescriptionCsv', false);
    },
    async exportCsv() {
      if (_.isEqual(_.get(state, 'operation'), Operation.NONE)) {
        try {
          _.set(state, 'operation', Operation.IS_GETTING);
          _.set(state, 'exportingCsv', true);
          _.set(state, 'exportCsv', false);
          const filters = Object.keys(state.filters).length > 0 ? state.filters : {};
          let calculations =
            Object.keys(state.calculations).length > 0 ? _.cloneDeep(state.calculations) : [];
          calculations.map((calculation) => {
            if (calculation.value === 'payout') calculation.value = `payout${currency}`;
            if (calculation.value === 'epc') calculation.value = `epc${currency}`;
            return {
              name: calculation.name,
              value: calculation.value,
            };
          });
          const options = _.get(state, 'options');
          const result = await HttpClientV2.callFunctionV2('POST', 'statistics/export', {
            calculations,
            ...filters,
            ...options,
          });
          _.set(state, 'exportId', result.data._id);
        } catch (error) {
          console.log(error);
        } finally {
          this.findExportProcess();
          _.set(state, 'operation', Operation.NONE);
        }
      }
    },
    async findExportProcess() {
      try {
        _.set(state, 'operation', Operation.IS_GETTING);
        let result = await HttpClientV2.callFunctionV2(
          'GET',
          `exportService/${state.exportId}`,
          null
        );
        let status = result.status;
        let error = result.errorDescription;
        while (status === 'PENDING') {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          result = await HttpClientV2.callFunctionV2(
            'GET',
            `exportService/${state.exportId}`,
            null
          );
          status = result.status;
        }
        if (status === 'DONE') {
          _.set(state, 'csvUrl', result.url);
        } else if (status === 'ERROR') {
          _.set(state, 'errorCsv', true);
          _.set(state, 'errorDescriptionCsv', error);
          _.set(state, 'showErrorDescriptionCsv', true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        _.set(state, 'doneCsv', true);
        _.set(state, 'exportingCsv', false);
        _.set(state, 'operation', Operation.NONE);
      }
    },
    async resetInitialState() {
      state.initialState = true;
    },
    async resetItems() {
      state.items = [];
      state.total = 0;
    },
    async resetInitialValues() {
      await this.resetInitialState();
      await this.resetItems();
      await this.resetFilters();
    },
  };
  return { state, actions };
}
