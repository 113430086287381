import axios from 'axios';
import HttpClientV2 from './HttpClientV2';
const USER_KEY = 'USER_KEY';
const TOKEN_KEY = 'TOKEN_KEY';
const SERVER_URL = process.env.VUE_APP_SERVER_URL;

const API = axios.create({
  baseURL: SERVER_URL,
  withCredentials: false,
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
});
export default {
  /**
   * Api call .
   * @param {Object} className
   * @param {Object} cloud
   * @param {Object} body
   */
  async runProcess(className, process, body) {
    try {
      const result = await API.post(`/api/${className}/${process}`, JSON.stringify(body), {
        headers: { 'x-access-token': localStorage.getItem(TOKEN_KEY) },
      });
      return result.data;
    } catch (error) {
      if (error.response && error.response.status === 403) {
        await this.singOut();
        window.location.href = '/';
      }
    }
  },
  /**
   * Uppload File
   * @param { FormData } formData
   */
  async upploadFile(formData) {
    let result = await API.post('/files/upload', formData, {
      headers: {
        'x-access-token': localStorage.getItem(TOKEN_KEY),
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  },
  /**
   * Sing In
   * @param {String} email
   * @param {String} password
   */
  async singIn({ email, password, forgotten }) {
    try {
      if (forgotten) {
        const result = await HttpClientV2.callFunctionV2('POST', 'affiliate/forgot-password', {
          email,
        });
        return result.data;
      } else {
        const result = await HttpClientV2.callFunctionV2('POST', 'login', {
          email: email,
          password: password,
          type: 'AFFILIATE',
        });
        if (result.response?.data?.status === 400) {
          throw { error: 'API_SINGIN_NOT_AUTHENTICATED_ERROR' };
        }
        localStorage.setItem(TOKEN_KEY, result.data.token);
        localStorage.setItem(USER_KEY, JSON.stringify(result.data.user));
        return result.data.user;
      }
    } catch (error) {
      if (error.response && error.response.data) throw error.response.data;
      throw error;
    }
  },
  async singOut() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
    return true;
  },
  isAuthenticated() {
    return !!localStorage.getItem(TOKEN_KEY);
  },
  currentUser() {
    let user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    } else {
      return null;
    }
  },
  changeThumbnail(thumbnail) {
    let user = localStorage.getItem(USER_KEY);
    if (user) {
      user = JSON.parse(user);
      user.thumbnail = thumbnail;
      localStorage.setItem(USER_KEY, JSON.stringify(user));
    } else {
      return null;
    }
  },
};
