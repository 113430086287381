import Vue from 'vue';
import Router from 'vue-router';
import Private from '@/view/Private';

const routes = [
  {
    path: '/',
    component: () => import('@/view/Public'),
    children: [
      {
        path: '',
        name: 'SingIn',
        component: () => import('@/view/SingIn'),
      },
    ],
  },
  {
    path: '/auth',
    component: Private,
    meta: { authorized: true },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/view/Affiliate/Dashboard'),
      },
      {
        path: 'offers',
        name: 'Offers',
        component: () => import('@/view/Affiliate/Offers'),
      },
      {
        path: 'offerGrid',
        name: 'OfferGrid',
        component: () => import('@/view/Affiliate/Offers/OfferGrid'),
      },
      {
        path: 'smartlinks',
        name: 'Smartlinks',
        component: () => import('@/view/Affiliate/Smartlinks'),
      },
      {
        path: 'smartlink/*',
        name: 'Smartlink',
        component: () => import('@/view/Affiliate/Smartlinks'),
      },
      {
        path: 'reports',
        name: 'Performance Report',
        component: () => import('@/view/Affiliate/ReportTS'),
      },
      {
        path: 'conversionReport',
        name: 'Conversion report',
        component: () => import('@/view/Affiliate/Conversions'),
      },
      {
        path: 'billing',
        name: 'Billing',
        component: () => import('@/view/Affiliate/Billing'),
      },
      {
        path: 'referral',
        name: 'Referral Program',
        component: () =>
          // Enabled only for Dev/Staging
          process.env.VUE_APP_STAGE === 'dev' || process.env.VUE_APP_STAGE === 'staging'
            ? import('@/view/Affiliate/Referral')
            : '',
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/view/Affiliate/AffiliateUser'),
      },
      {
        path: 'my',
        name: 'My',
        component: () => import('@/view/Affiliate/My'),
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import('@/view/Affiliate/Support'),
      },
    ],
  },
];

Vue.use(Router);
const router = new Router({
  routes: routes,
});
export default router;
