const en = {
  language: {
    en: 'English',
    es: 'Spanish',
    pt: 'Portuguese',
  },
  _and_: 'And',
  _or_: 'Or',
  _not_: 'Not',
  _equal_to_: 'Equal',
  _not_equal_: 'Not Equal',
  _greater_than_: 'Greater Than',
  _greater_than_or_equal_to_: 'Greater Than Or Equal',
  _less_than_: 'Less Than',
  _less_than_or_equal_to_: 'Less Than Or Equal',
  settings: {
    settings: 'Settings',
    language: 'Language',
    singOut: 'Sing Out',
    theme: 'Theme',
  },
  commons: {
    titles: {
      affiliateNetwork: 'Affiliate Network Stats',
      TermsConditions: 'Terms & Conditions',
      requestApproval: 'Request Approval',
      targetingCapping: 'Targeting & Capping',
      thirdPartyTracking: 'Third Party Tracking',
      creatives: 'Creatives',
      detailsTrackinglink: 'Details & Tracking link',
      userProfile: 'User Profile',
      changePassword: 'Change Password',
      accountInformation: 'Account Information',
      billingSettings: 'Billing Settings',
      apiIntegration: 'API Integration',
      signUpUrl: 'Sign Up URL',
      referralUrl: 'Referral URL',
    },
    operation: {
      send: 'Send',
      search: 'Search',
      save: 'Save',
      edit: 'Edit',
      update: 'Update',
      delete: 'Delete',
      cancel: 'Cancel',
      confirm: 'Confirm',
      accept: 'Accept',
      select: 'Select',
      duplicate: 'Duplicate',
      canSee: 'Can see',
      canCreate: 'Can create',
      canEdit: 'Can edit',
      canDelete: 'Can delete',
      addNewItem: 'Add new item',
      back: 'Back',
      goBack: 'Go back',
      seeMore: 'See more',
      add: 'Add',
      remove: 'Remove',
      groupBy: 'Group By',
      exportToPdf: 'Export to pdf',
      download: 'Download',
      copy: 'Copy',
      logOut: 'Log out',
      addMacros: 'Add Macros',
      selectAvailableMacros: 'Select available macros...',
    },
    rules: {
      errorRequired: 'This field is required',
      errorMinLength: 'At least %{count} characters',
      errorMaxLength: 'Max %{count} characters',
      errorInvalid: 'Field must be valid',
      errorInvalidEmail: 'E-mail must be valid',
      errorInvalidPassword: 'Password must be valid',
      errorInvalidIp: 'Ip must be valid',
      errorInvalidRange: 'Range must be valid',
      errorInvalidVersion: 'Version must be valid',
      errorInvalidUrl: 'Url must be valid',
      errorMustBeNumeric: 'The value must be numeric.',
      errorIban: 'This field only allows letters and numbers',
    },
    error: {
      connectionError: 'Connection error, please check your network.',
      unknownError:
        '%{status} - Internal service error, we are working to resolve it please report it to help us.',
      tokenMissingError: 'Your session has expired, please start it again.',
      validations: {
        REQUIRED: 'These fields are required: %{items}.',
        UNIQUE: 'These fields must be unique: %{items}.',
        EMAIL: 'These fields must be valid emails: %{items}.',
        FIELD_TYPE: 'These fields are of invalid type: %{items}.',
        FOREIGN_KEY: 'The following data is linked: %{items}.',
        UNKNOWN: 'Unknown validation',
      },
      unsupportedType: 'Unsupported type %{type}.',
      unsupportedPrimitiveFieldType: 'Unsupported primitive field type %{type}.',
      unsupportedRelationshipClassType: 'Unsupported relationship class type %{classType}.',
    },
    success: {
      success: 'Success.',
      created: 'Created successfully.',
      updated: 'Updated successfully.',
      deleted: 'Deleted successfully.',
    },
    filter: {
      apply: 'Apply filters to perform a search.',
      show: 'Show filters',
      hide: 'Hide filters',
    },
    table: {
      search: 'Search',
      from: 'From',
      to: 'to',
      of: 'of',
      noDataText: 'No data available',
      noResultsText: 'No matching records found',
      rowsPerPageText: 'Items per page:',
    },
    tree: {
      noDataText: 'No data available',
    },
    mockup: {
      box: 'Show box mode',
      laptop: 'Show laptop mode',
      tablet: 'Show tablet mode',
      phone: 'Show phone mode',
    },
    theme: {
      background: 'Background',
      backgroundColor: 'Color',
      elevation: 'Elevation',
      margin: 'Margin',
      marginTop: 'Top',
      marginRight: 'Right',
      marginBottom: 'Bottom',
      marginLeft: 'Left',
      padding: 'Padding',
      paddingTop: 'Top',
      paddingRight: 'Right',
      paddingBottom: 'Bottom',
      paddingLeft: 'Left',
      border: 'Border',
      borderWidth: 'Width',
      borderColor: 'Color',
      borderStyle: 'Style',
      borderRadius: 'Radius',
      text: 'Text',
      color: 'Color',
      textAlign: 'Align',
      textDecoration: 'Decoration',
      textTransform: 'Transform',
      fontStyle: 'Style',
      fontWeight: 'Weight',
      fontSize: 'Size',
    },
    model: {},
    crud: {
      itemSelected: '%{className} selected.',
    },
  },
  data: {
    language: {
      language: 'Language',
      en: 'English',
      es: 'Spanish',
      pt: 'Portuguese',
    },
    metric: {
      promoterScoreType: {
        PROMOTER: 'PROMOTER',
        PASSIVE: 'PASSIVE',
        DETRACTOR: 'DETRACTOR',
      },
      effortScoreType: {
        EASY: 'EASY',
        DIFFICULT: 'DIFFICULT',
      },
    },
    incident: {
      incidentType: {
        SATISFACTORY: 'SATISFACTORY',
        UNSATISFACTORY: 'UNSATISFACTORY',
      },
      incidentStatus: {
        SENT: 'SENT',
        ISSUED: 'ISSUED',
        IN_ACTION: 'IN ACTION',
        RESOLVED: 'RESOLVED',
        UNSOLVED: 'UNSOLVED',
        REJECTED: 'REJECTED',
      },
      incidentOperation: {
        PERCENTAGE_SATISFACTORY: 'SATISFACTORY',
        PERCENTAGE_UNSATISFACTORY: 'UNSATISFACTORY',
        AVERAGE_TROUBLESHOOTING: 'TROUBLESHOOTING',
      },
    },
    survey: {
      surveyState: {
        PENDING: 'PENDING',
        FAILED: 'FAILED',
        SENT: 'SENT',
        READ: 'READ',
        ANSWERED: 'ANSWERED',
      },
      question: {
        type: {
          RATING: 'RATING',
          COMMENT: 'COMMENT',
          BOOLEAN: 'BOOLEAN',
          TEXT: 'TEXT',
          DROPDOWN: 'DROPDOWN',
          RADIOGROUP: 'RADIOGROUP',
        },
        flag: {
          PROMOTER_SCORE: 'PROMOTER SCORE',
          EFFORT_SCORE: 'EFFORT SCORE',
          SATISFACTION_SCORE: 'SATISFACTION SCORE',
          BRAND_SCORE: 'BRAND SCORE',
          SATISFACTION_INDICATOR: 'SATISFACTION',
          INSATISFACTION_INDICATOR: 'INSATISFACTION',
        },
        visibleIfCondition: {
          contains: 'Contains',
          '>': 'Greater than',
          '>=': 'Greater than or equal to',
          '<': 'Less than',
          '<=': 'Less than or equal to',
          '=': 'Equal',
        },
      },
    },
    tagger: {
      level: {
        CATEGORY: 'CATEGORY',
        TAG: 'TAG',
      },
    },
    sentiment: {
      scoreType: {
        POSITIVE: 'POSITIVE',
        NEUTRAL: 'NEUTRAL',
        NEGATIVE: 'NEGATIVE',
      },
    },
  },
  metric: {
    promoterScore: 'Promoter Score',
    promoterScoreTypePromoter: 'Promoter',
    promoterScoreTypePassive: 'Passive',
    promoterScoreTypeDetractor: 'Detractor',
    promoterScoreDetail: 'Promoter %{promoter}% - Passive %{passive}% - Detractor %{detractor}%.',
    effortScore: 'Effort Score',
    effortScoreTypeEasy: 'Easy',
    effortScoreTypeDifficult: 'Difficult',
    promoterScoreVsEffortScore: 'Promoter Score vs Effort Score',
    brandScore: 'Brand Score',
    brandScoreTypeLove: 'Love',
    brandScoreTypeDislike: 'Dislike',
    satisfactionScore: 'Satisfaction Score',
    satisfactionScoreTypeSatisfied: 'Satisfied',
    satisfactionScoreTypeDissatisfied: 'Dissatisfied',
    brandScoreVsSatisfactionScore: 'Brand Score vs Satisfaction Score',
  },
  menu: {
    menu: 'Menu',
    dashboard: 'Dashboard',
    sentiment: 'Sentiment',
    improvements: 'Improvements',
    incidents: 'Incidents',
    surveyScheduled: 'Scheduled',
    surveyLibrary: 'Library',
    surveyDesign: 'Design',
    users: 'Users',
    roles: 'Roles',
    locations: 'Locations',
    segments: 'Segments',
    momentsOfTruth: 'Moments of Truth',
    customers: 'Customers',
    clusters: 'Clusters',
    channels: 'Channels',
    textTagger: 'Text tagger',
    settings: 'Settings',
  },

  singIn: {
    continue: 'Continue',
    logIn: 'LOGIN',
    newAccount: 'New on our platform?',
    singUp: 'Create an account',
    account: 'Account',
    accountRulesRequired: 'Account is required.',
    email: 'E-mail',
    emailRulesRequired: 'E-mail is required.',
    emailRulesInvalid: 'E-mail must be valid.',
    password: 'Password',
    passwordRulesRequired: 'Password is required.',
    newPasswordRulesRequired: 'New password is required.',
    passwordRulesMinLength: 'At least 8 characters.',
    API_SINGIN_NOT_AUTHENTICATED_ERROR: 'Invalid credentials. Please try again.',
    API_SINGIN_MISSING_PARAMETERS_ERROR: 'Invalid credentials. Please try again.',
    API_SINGIN_BLOCKED_ERROR: 'Your account has been blocked.',
    API_SINGIN_PENDING_ERROR: 'Your account is pending approval.',
    API_SINGIN_DELETED_ERROR: 'Your account has been deleted.',
    API_SINGIN_INACTIVE_ERROR: 'Your account has been blocked.',
    forgotten: 'Forgot Password?',
    singIn: 'Return to sign in',
    recovery: 'An email was sent to %{email} with the password recovery information.',
    emailToLinkRecovery: 'Please enter your email to get the link for password recovering.',
    submit: 'SUBMIT',
  },
  singUp: {
    continue: 'Continue',
    singIn: 'Sing In',
    account: 'Account',
    accountRulesRequired: 'Account is required.',
    accountCode: 'Account Code',
    accountCodeRulesRequired: 'Account code is required.',
    email: 'E-mail',
    emailRulesRequired: 'E-mail is required.',
    emailRulesInvalid: 'E-mail must be valid.',
    invalidCredentials: 'The account is already registered.',
    singUpSuccess: 'Successfully created account, check your email for more information.',
  },
  dashboard: {
    menu: {
      historical: 'Historical',
      comparative: 'Comparative',
    },
    title: {
      titleChart: 'Clicks & Conversions & Payout',
      titleList: 'Top Performing Campaings',
      titleRealTime: '(Real-time Performance)',
      titleMonth: '(Last 7 days)',
    },
    today: 'Today',
    month: 'This Month',
    lastMonth: 'Last Month',
    monthForecast: 'Month Forecast',
    payoutToday: 'Payout (Today)',
    payoutMonth: 'Payout (this month)',
    payoutLastMonth: 'Payout (last month)',
    payoutMonthForecast: 'Payout (month forecast)',
    from: 'From',
    to: 'To',
    location: 'Location',
    segment: 'Segment',
    momentOfTruth: 'Moment of Truth',
    cluster: 'Cluster',
    channel: 'Channel',
    groupBy: 'Group by %{groupBy}',
  },
  sentiment: {
    sentiment: 'Sentiment',
    categories: 'Categories',
    tags: 'Tags',
    comments: 'Comments',
    comment: 'Comment',

    positive: 'Positive',
    neutral: 'Neutral',
    negative: 'Negative',

    total: 'Total',
    score: 'Score',

    location: 'Location',
    segment: 'Segment',
    momentOfTruth: 'Moment of Truth',
    cluster: 'Cluster',
    channel: 'Channel',
    customer: 'Customer',
    textTagger: 'Text tagger',
  },
  incidents: {
    satisfactoryVsUnsatisfactory: 'Satisfactory vs Unsatisfactory',
    troubleshooting: 'Troubleshooting',
    percentageSatisfactory: '% Satisfactory',
    percentageUnsatisfactory: '% Unsatisfactory',
    sigmaSatisfactory: 'Σ Satisfactory',
    sigmaUnsatisfactory: 'Σ Unsatisfactory',
    total: 'Σ Total',
    location: 'Location',
    segment: 'Segment',
    momentOfTruth: 'Moment of Truth',
    cluster: 'Cluster',
    channel: 'Channel',
    customer: 'Customer',
    type: 'Type',
    status: 'Status',
    description: 'Description',
    leaveAComment: 'Leave a comment...',
    switchTo: 'Switch to %{status}',
    switchedTo: 'The status %{previousStatus} has been switched to %{currentStatus}.',
  },
  locations: {
    title: 'Locations',
    continents: 'Continents',
    region: 'Region',
    countries: 'Countries',
    cities: 'Cities',
  },
  devices: {
    title: 'Devices',
    deviceType: 'Device Type',
    deviceClient: 'Device Client',
    deviceClientBrowser: 'Browser',
    deviceBrand: 'Device Brand',
    deviceOs: 'Device OS',
    connectionType: 'Connection Type',
    deviceCarrier: 'Carrier',
    deviceOsVersionMinimum: 'Minimum Device Os Version (^v1.2 | 3.*)',
    deviceOsVersionMaximum: 'Maximum Device Os Version (^v1.2 | 3.*)',
    deviceClientVersionMinimum: 'Minimum Client Version (^v1.2 | 3.*)',
    deviceClientVersionMaximum: 'Maximum Client Version (^v1.2 | 3.*)',
  },
  offers: {
    title: 'Offers',
  },
  smartLink: {
    title: 'Smartlinks',
  },
  billing: {
    title: 'Billing',
    fields: {
      _id: 'Identifier',
      invoiceNumber: 'Invoice Number',
      documentNumber: 'Document Number',
      documentType: 'Document Type',
      documentStatus: 'Document Status',
      paymentNoteStatus: 'Payment Note Status',
      invoiceStatus: 'Invoice Status',
      startDate: 'Start Date',
      endDate: 'End Date',
      dateRange: 'Date Range',
      amount: 'Amount',
      createdAt: 'Created At',
      actions: 'Actions',
    },
  },
  referral: {
    title: 'Referral Program',
    url: 'Referral URL',
    list: 'Referral List',
    commissions: 'Referral Commission Calculations',
    dateRange: 'Date Range',
    totals: 'Totals',
    fields: {
      _referredAffiliate: 'Referred Affiliate',
      referredAffiliates: 'Referred Affiliates',
      status: 'Status',
      commission: 'Commission',
      commisionPeriod: 'Commission Period',
      amount: 'Amount',
      createdAt: 'Starting Date',
    },
  },
  reports: {
    title: 'Reports',
    visualReport: 'Visual Reports',
    detailedReport: 'Detailed Reports',
  },
  conversions: {
    title: 'Conversions',
    general: 'General',
  },
  transactions: {
    identifier: 'Identifier',
    transactionId: 'Transaction ID',
    conversionId: 'Conversion ID',
    status: 'Status',
    advertiser: 'Advertiser',
    offer: 'Offer',
    affiliate: 'Affiliate',
    errorId: 'The value must be a valid ID',
  },
  model: {
    aling: 'Aling',
    as: 'As',
    className: 'Class name',
    classType: 'Class type',
    edition: 'Edition',
    email: 'Email',
    fields: 'Fields',
    filtered: 'Filtered',
    filters: 'Filters',
    foreignField: 'Foreign field',
    from: 'From',
    icon: 'Icon',
    index: 'Index',
    label: 'Label',
    list: 'List',
    localField: 'Local field',
    name: 'Name',
    operator: 'Operator',
    password: 'Password',
    relation: 'Relation',
    required: 'Required',
    selection: 'Selection',
    show: 'Show',
    sortable: 'Sortable',
    type: 'Type',
    unique: 'Unique',
    userInterface: 'User interface',
    width: 'Width',
    _id: 'Identifier',
    account: 'account',
    database: 'Database',
    createdAt: 'Created At',
    updatedAt: 'Updated At',

    PostEventNotifications: {
      conversionNotification: 'Conversion Notification',
      conversionNotifications: 'Conversion Notifications',
      transactionNotification: 'Transaction Notification',
      transactionNotifications: 'Transaction Notifications',
      type: 'Notification Type',
      url: 'Notification Url',
      action: 'Action',
    },
    AffiliatePixels: {
      className: 'Affiliate Pixels',
      singular: 'Affiliate Pixel',
      plural: 'Affiliate Pixels',
      fields: {
        _id: 'Identifier',
        status: 'Status',
        _offer: 'Offer',
        _affiliate: 'Affiliate',
        type: 'Type',
        url: 'Url',
        retryPolicy: 'Retry Policy',
        retryPolicyConfig: 'Retry Policy Configuration',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        result: 'Notification Result',
        events: 'Event Trigger',
      },
      limitReached:
        'The Postback installed have reached the maximum allowed, please delete any of the current installed Postbacks to install new ones.',
    },
  },
};
export default en;
